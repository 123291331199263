import React from "react";
import styled from 'styled-components';


const StyledSlider = styled.div`

.sliderwrapper {
    width: 100%;
   -webkit-transition: -webkit-transform 0.3s;
   transition: transform 0.3s;
} 
`;



const Slider = (props) => {

        return (
            <>
            <StyledSlider id="slider" className="overflow-x-scroll flex flex-no-wrap no-scroll-bar" >
                <div  className="flex flex-no-wrap no-scroll-bar">
                    <div className="sliderwrapper flex items-center flex-no-wrap">
                    {props.children}
                    </div>
                </div>
            </StyledSlider>

                <div className="flex justify-center pb-4">
                   {props.dots}
                </div>
                
            </>
        );
   
  
}


export default Slider
