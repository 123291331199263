import React, {useState, useEffect } from 'react';
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Slider from "../components/Slider"
import catalyst from "./../content/images/catalyst-press-logo-green.svg"


const Index = (props) => {


    const { data } = props
    const siteTitle = data.site.siteMetadata.title
    const img = data.allFile.edges

    const moveSlider = (event) => {
      event.preventDefault() // Prevent default
      var slideIdValue = parseInt( event.target.dataset.id, 10) 
      var myElement = document.querySelector('.sliderwrapper');
      if( slideIdValue >= 1 && slideIdValue < 3 ) {
          myElement.style.transform = "translateX(" + (- slideIdValue * 100) + "%)";
      } 
       else {
          myElement.style.transform = "translateX(" + (0) + "%)";
      }
  }

 
    const Carousel = () => {

      var slides = [
        { "id": "1",
          "imageName": "bridget-Krone-author",
          "caption": "Author Bridget Krone releases her new book <i>The Cedarville Shop and the Wheelbarrow Swap</i>",
          "captionPosition": "bottom-0 pb-3  sm:top-0 sm:right-0"
        },
        { "id": "2",
        "imageName": "the-cedarville-shop-landscape",
        "caption": "<a   href='/books/The%20Cedarville%20Shop%20and%20the%20Wheelbarrow%20Swap' ><button class='bg-blue-500 hover:bg-red-700 text-lg rounded-full py-3 px-4 shadow-md'>The Cedarville Shop and the Wheelbarrow Swap</button></a>",
        "captionPosition": "left-0 right-0 bottom-0 pb-3"
      },
        { "id": "3",
          "imageName": "small-mercies-hero",
          "caption": "<a   href='https://www.indiebound.org/book/9781946395160' ><button class='bg-red-500 hover:bg-red-700 text-lg rounded-full py-3 px-4 shadow-md'>This edition is only available in USA and Canada</button></a>",
          "captionPosition": "left-0 right-0 bottom-0 pb-3"
        },
        { "id": "4",
          "imageName": "small-mercies-hero-walker",
          "caption": "<a  href='http://www.walker.co.uk/' ><button class='bg-red-500 hover:bg-red-700 text-lg rounded-full py-3 px-4 shadow-md'>This edition is only available in the UK and Commonwealth countries, including South Africa </button></a>",
          "captionPosition": "left-0 right-0 bottom-0 pb-3"
        }
      ]


      const [slideNo, setSlideNo] = useState(1);
   
       useEffect(() => {

        document.querySelector('#carousel-'+ slideNo).checked=true
  
        const id = setInterval(() => { 
          var slideId = document.querySelector('.carousel-open:checked');
          var slideIdValue = slideId ? parseInt( slideId.dataset.id, 10) : 2 ;
          var nextSlide = slideIdValue + 1

          if ( nextSlide > slides.length ) {
            document.querySelector('#carousel-1').checked=true;
          }
          else {
            document.querySelector('#carousel-'+nextSlide).checked=true;
          }
          setSlideNo(nextSlide);
        }, 7000);
        return () => clearInterval(id);
    
      }, []);


      return (
        <div className="carousel">
        <div className="carousel-inner">
        <div className="relative h-hero-fluid ">
            {slides.map( (y)  => {
                return (
                  <div key={y.id} id={y.id} className="animated slowest fadeIn w-screen ">
                    <input className="carousel-open"  type="radio" data-id={y.id} id={"carousel-" + y.id} name="carousel" aria-hidden="true"   />
                    <div className="carousel-item w-full relative">

                      {img.filter(x => x.node.name === y.imageName ).map(({ node }) => {
                            return ( <Img key={node.name} className="w-screen h-hero-fluid"   fluid={node.childImageSharp.fluid} alt="..."/>)
                      })}

                      <div className={"absolute z-20  sm:max-w-sm lg:max-w-xl mx-auto " + y.captionPosition}>
                        <div className="p-8 sm:mt-12 sm:mr-12 lg:mt-20 lg:mr-20">
                          <div className="text-2xl md:text-3xl lg:text-4xl text-white  tracking-wider leading-tight lg:leading-none text-shadow font-bold uppercase animated slideInUp "  dangerouslySetInnerHTML={{ __html: y.caption}} />
                        </div>
                      </div>

                    </div>
                  </div>
            )})}
        </div>  

        <ol className="carousel-indicators">
        {slides.map( (y)  => ( 
            <li key={y.id}>
              <label htmlFor={"carousel-" + y.id} className="carousel-bullet">•</label>
            </li>
        ))}
        </ol>
        </div>
        </div>
     ) 
     }




    return (
      <Layout location={props.location} title={siteTitle}>

        <Helmet>
         <meta name="google-site-verification" content="HTCj6r-N7GxfMqd25gW5i4ptVOzyj45qc0x-8rO8aag" />
        </Helmet>

          
        <SEO
          title="The official site of Bridget Krone - Children's Book Author"
          keywords={[`Author`, `childrens book`, `small mercies`, 'The Cedarville Shop and the Wheelbarrow Swap' ]}
        />
       
       <Carousel />

       {/*AWARDS*/}


      <div className="w-full text-center pt-12 py-12">
              <span className=" text-xl sm:text-2xl font-bold tracking-wider text-gray-600 py-4 px-10"> AWARDS: <i>The Cedarville Shop and the Wheelbarrow Swap</i> </span>
        </div>

        <div className="w-full flex flex-col mx-auto max-w-4xl justify-center">

            <div className="w-full flex flex-col  justify-center items-center ">
              <div className="w-56 h-56">
              {img.filter(x => x.node.name == "the-cedarville-JLG").map(({ node }) => {
                    return ( <Img className="" key={node.name}  fluid={node.childImageSharp.fluid} alt="..."/>)
              })}
              </div>
              <div className="p-8 rounded-lg leading-tight h-auto text-gray-600 text-center">
                  <div className="text-xl font-semibold white pb-2 tracking-wider"> A Junior Library Guild Gold Standard Selection </div>
                  <div className="text-base white pb-4 tracking-wider"> This award is given to books before publication. 95% of the books that receive this award go on to win awards, honours, starred reviews and industry accolades.</div>
              </div>
            </div>
      </div>
        
        
        <div className="w-full text-center pt-12 py-12">
              <span className="text-xl sm:text-2xl font-bold tracking-wider text-gray-600 py-4"> AWARDS: <i>Small Mercies</i> </span>
        </div>

        <div className="w-full flex flex-col mx-auto max-w-4xl justify-center">

            <div className="w-full flex flex-col  justify-center items-center ">
              <div className="w-full px-8 max-w-sm">
              {img.filter(x => x.node.name === "kirkus_review_awards").map(({ node }) => {
                    return ( <Img className="w-50" key={node.name}  fluid={node.childImageSharp.fluid} alt="..."/>)
                  })}
              </div>
              <div className="p-8 rounded-lg leading-tight h-auto text-gray-600 text-center">
                  <div className="text-xl font-semibold white pb-2 tracking-wider"> KIRKUS </div>
                  <div className="text-base white pb-4 tracking-wider"> <i>Small Mercies</i> has been chosen by Kirkus in the USA as one of the best books of 2020. The list is drawn from those books that were awarded a starred review - which <i>Small Mercies</i> got earlier this year. One of the most coveted designations in the book industry, the Kirkus star marks books of exceptional merit. </div>
                  <a className="text-base block pb-6" href="https://www.kirkusreviews.com/news-and-features/articles/best-of-2020-our-favorite-middle-grade-books/">
                  <span className="text-green-500 hover:text-green-800">Click here to see the awarded list </span>
                  </a>
              </div>
            </div>

            <div className="w-full flex flex-col  justify-center items-center pt-12">
              <div className="w-full px-8 max-w-3xl">
              {img.filter(x => x.node.name === "2021-oib_orig").map(({ node }) => {
                    return ( <Img className="" key={node.name}  fluid={node.childImageSharp.fluid} alt="..."/>)
                  })}
              </div>
              <div className="p-8 rounded-lg leading-tight h-auto text-gray-600 text-center">
                  <div className="text-xl font-semibold white pb-2 tracking-wider"> USBBY </div>
                  <div className="text-base white pb-4 tracking-wider"> <i>Small Mercies</i> made it onto the list of “Outstanding International Books” put out annually by the USBBY (United States Board of Books  for Young people). This years list includes books from New Zealand, Norway, Mexico and all kind wonderful of far-off places. </div>
                  <a className="text-base block pb-6" href="https://www.usbby.org/outstanding-international-books-list.html">
                  <span className="text-green-500 hover:text-green-800">Click here to see the awarded list </span>
                  </a>
              </div>
          </div>


          <div className="w-full flex flex-col  justify-center items-center pt-12">
              <div className="w-64 h-64  max-w-3xl">
              {img.filter(x => x.node.name === "small-mercies-skipping-stones-honour-award").map(({ node }) => {
                    return ( <Img className="" key={node.name}  fluid={node.childImageSharp.fluid} alt="..."/>)
                  })}
              </div>
              <div className="p-8 pt-8 rounded-lg leading-tight h-auto text-gray-600 text-center">
                  <div className="text-xl font-semibold white pb-2 tracking-wider"> Skipping Stones Honour Award </div>
                  <div className="text-base white pb-4 tracking-wider"> <i>Small Mercies</i> received the Skipping Stones Honour Award. These awards are given to books that promote an understanding of cultures, cultivate co-operation and/or encourage a deeper awareness of nature, ecology and diversity.</div>
                  <a className="text-base block pb-6" href="https://www.skippingstones.org/wp/">
                  <span className="text-green-500 hover:text-green-800">Click here to go to Skipping Stones </span>
                  </a>
              </div>
          </div>

        </div>
       


      {/*MEDIA ACKNOWLEDGEMENT*/}
      <div className="w-full h-auto  bg-gray-100">
      <Slider dots = {
          <ul>
            <li data-id={1} onPointerUp={moveSlider} className="inline-block text-gray-700 text-center bg-gray-400 p-2 m-2 rounded-full hover:bg-gray-500"></li>
            <li data-id={2} onPointerUp={moveSlider} className="inline-block text-gray-700 text-center bg-gray-400 p-2 m-2 rounded-full hover:bg-gray-500"></li>
            <li data-id={3} onPointerUp={moveSlider} className="inline-block text-gray-700 text-center bg-gray-400 p-2 m-2 rounded-full hover:bg-gray-500"></li> 
          </ul>
        } 
        >


          <div data-id={1} className="w-screen h-auto  px-4 py-12 sm:p-20 flex-none"  onKeyUp={moveSlider} onPointerUp={moveSlider} >
            <div className="flex flex-col mx-auto max-w-4xl text-lg sm:text-2xl font-thin text-gray-600 text-center tracking-wider leading-tight uppercase">
            <span className="text-gray-900 text-sm tracking-wider pb-4">Small Mercies by Bridget Krone</span>
              <span className="font-bold">"Sensitive, funny and tender”</span> 
              <span className=" text-lg pt-4"> Kirkus starred review </span>
            </div>
          </div>

          <div data-id={2} className="w-screen h-auto px-4 py-12 sm:p-20 flex-none" onKeyUp={moveSlider} onPointerUp={moveSlider}  >
            <div className="flex flex-col mx-auto max-w-4xl text-lg sm:text-2xl font-thin text-gray-600 text-center tracking-wider leading-tight uppercase">
            <span className="text-gray-900 text-sm tracking-wider pb-4">Small Mercies by Bridget Krone</span>
              <span className="font-bold">"An offering of Love."</span> 
              <span className=" text-lg pt-4"> Kathi Appelt, New York Times Bestseller, Newbery Honor Award and National Book Honor winner</span> 
            </div>
          </div>

          <div data-id={3} className="w-screen h-auto px-4 py-12 sm:p-20 flex-none"  onKeyUp={moveSlider} onPointerUp={moveSlider}  >
            <div className="flex flex-col mx-auto max-w-4xl font-thin text-gray-600 text-center tracking-wider leading-tight uppercase">
              <span className="text-gray-900 text-sm tracking-wider pb-4">Small Mercies by Bridget Krone</span>
              <span className="font-semibold text-base sm:text-lg  leading-relaxed">“Tender, exhilarating, and often hilarious, Small Mercies perfectly weaves the difficult and the wonderful truths of being alive. You will root for Mercy from the very first page, and she will be in your heart long after you’ve read this vivid, beautifully written novel.”
              </span>
              <span className=" text-lg pt-4"> Sara Cassidy, Author of A Boy Named Queen</span>
            </div>
          </div>
          
        </Slider>
        </div>


    


 

{/*BOOKS*/}     
<div className="w-full h-auto  p-8 " style={{backgroundColor: 'rgb(202,220,217)' }}>
<div className="flex flex-col sm:flex-row  mx-auto max-w-4xl">

    <div className="w-full sm:w-1/2 flex flex-col  justify-center items-center ">
        <div className="w-40 object-cover pt-4 pb-2">
        <img className="" src={catalyst} alt="..."/>
        </div>
        <div className="text-gray-800 p-8 rounded-lg leading-tight h-auto mt-4" style={{backgroundColor: 'rgb(150,190,190)' }}>
            <div className="text-xl font-semibold  pb-2 tracking-wider"> Catalyst Press </div>
            <div className="text-lg font-semibold  pb-4 tracking-wider"> This edition is only available in USA and Canada </div>

            <a className="text-base block pb-6 cursor-pointer" href="https://www.catalystpress.org/young-adult-books/small-mercies/" >
            <span className="text-gray-800 hover:text-gray-100">Click here to review Small Mercies at the Catalyst Press website</span>
            </a>

            <div className='text-gray-800 font-semibold text-base mb-2'>Buy from the following stores:</div>

          <a className="text-base block pb-1 cursor-pointer text-gray-800 hover:text-gray-100" href="https://www.indiebound.org/book/9781946395177" >IndieBound</a>
          <a className="text-base block pb-1 cursor-pointer text-gray-800 hover:text-gray-100" href="https://www.amazon.com/Small-Mercies-Bridget-Krone/dp/1946395161" >Amazon</a>
          <a className="text-base block pb-1 cursor-pointer text-gray-800 hover:text-gray-100" href="https://www.barnesandnoble.com/w/small-mercies-bridget-krone/1129445100" >Barnes & Noble</a>
          <a className="text-base block pb-1 cursor-pointer text-gray-800 hover:text-gray-100" href="https://www.catalystpress.org/young-adult-books/small-mercies/" >Catalyst website</a>

        </div>
    </div>

    <div className="w-full sm:w-1/2 p-12">
    {img.filter(x => x.node.name === "small-mercies-soft-cover-blue").map(({ node }) => {
          return ( <Img className="" key={node.name}  fluid={node.childImageSharp.fluid} alt="..."/>)
        })}
    </div>

</div>
</div>



<div className="flex flex-col sm:flex-row h-auto max-w-4xl mx-auto p-8">
  <div className="w-full sm:w-1/2 ">
    {img.filter(x => x.node.name === "small-mercies-walker-books-soft-cover").map(({ node }) => {
          return ( <Img className="" key={node.name}  fluid={node.childImageSharp.fluid} alt="..."/>)
        })}
    </div>
    <div className="w-full sm:w-1/2 flex flex-col  justify-center items-center ">
      <div className="w-32 object-cover pt-6 pb-2">
      {img.filter(x => x.node.name === "walker-books-logo").map(({ node }) => {
          return ( <Img className="" key={node.name}  fluid={node.childImageSharp.fluid} alt="..."/>)
        })}
      </div>
        <div className="bg-pink-700 text-white p-8 rounded-lg leading-tight h-auto mt-4">
          <div className="text-xl font-semibold white pb-2 tracking-wider"> Walker Books </div>
            <div className="text-lg font-semibold white pb-4 tracking-wider"> This edition is only available in the UK and Commonwealth countries </div>

          <a className="text-base block pb-6" href="http://www.walker.co.uk/">
          <span className="hover:text-pink-300">Click here to review Small Mercies at the Walker Books website</span>
          </a>

          <div className='text-white font-semibold text-base mb-2'>Buy from the following stores:</div>

          <a className="text-base block pb-1 cursor-pointer hover:text-pink-300" href="https://www.amazon.co.uk/Small-Mercies-Bridget-Krone/dp/1406391808" >Amazon UK</a>
          <a className="text-base block pb-1 cursor-pointer hover:text-pink-300" href="https://www.bookdepository.com/Small-Mercies-Bridget-Krone/9781406391800" >Book Depository</a>
          <a className="text-base block pb-1 cursor-pointer hover:text-pink-300" href="https://www.booksetc.co.uk/books/view/-9781406391800" >Books Etc</a>


      </div>
    </div>
  </div>


</Layout>
)
}



export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: { extension: { eq: "jpg" }  }) {
        edges {
          node {
           name 
           childImageSharp{
                  fluid( maxWidth: 1280, quality: 90 ) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
          }
        }
      }
    allMdx (sort: { order: ASC, fields: [frontmatter___order] }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            order
            category
            description
            embeddedImagesRemote {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            embeddedImagesLocal {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
export default Index